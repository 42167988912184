import React from "react";

import Contactus from "../components/sharedComponents/Contact-us";

import Seo from "../components/seo/index";

import Layout from "../layout/index";

const Contactuspage = () => {
  return (
    <div>
      <Seo title="Green Heartfulness Run 2024 - Contact Us" />
      <Layout isSticky>
        <Contactus />
      </Layout>
    </div>
  );
};

export default Contactuspage;
