import React from "react";


import "../../assets/styles/pages/contact-us/contact-us.scss";




const  Contactus = () => {

    return (
        <>
            <section className="contact-us inner-pages">
                <div className="container-fluid p-0">
                    <div className="row py-4">
                        <div className="col-md-12 text-center">
                            <h2 className="text-center mb-3 section-title">Contact Us</h2>
                            </div>
                            
                       </div>
                </div>
                <div className="container" style={{marginBottom: '100px'}}>
                    <div className="row">
                        <div className="col-sm-6 mobile-view">

                            <div className="contact-icon">
                                <span class="contact-ct-1"><svg width="64px" height="64px" viewBox="-11.52 -11.52 47.04 47.04" xmlns="http://www.w3.org/2000/svg" fill="#000000" stroke="#000000" stroke-width="0"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_iconCarrier"> <g> <path fill="none" d="M0 0h24v24H0z"></path> <path d="M17.084 15.812a7 7 0 1 0-10.168 0A5.996 5.996 0 0 1 12 13a5.996 5.996 0 0 1 5.084 2.812zM12 23.728l-6.364-6.364a9 9 0 1 1 12.728 0L12 23.728zM12 12a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"></path> </g> </g></svg>D. No: 13-110, Kanha Village,<br /> Nandigama Mandal,<br /> Ranga Reddy District,<br /> Telangana, India Pin – 509325</span>
                            </div>
                            <div className="contact-icon">
                                <span class="contact-ct-1"><svg width="64px" height="64px" viewBox="-11.52 -11.52 47.04 47.04" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_iconCarrier"> <path d="M4 3H7.5C8.05 3 8.5 3.45 8.5 4C8.5 5.25 8.7 6.45 9.07 7.57C9.18 7.92 9.1 8.31 8.82 8.59L6.62 10.79C8.06 13.62 10.38 15.93 13.21 17.38L15.41 15.18C15.61 14.99 15.86 14.89 16.12 14.89C16.22 14.89 16.33 14.9 16.43 14.94C17.55 15.31 18.76 15.51 20 15.51C20.55 15.51 21 15.96 21 16.51V20C21 20.55 20.55 21 20 21C10.61 21 3 13.39 3 4C3 3.45 3.45 3 4 3Z" fill="#000000"></path> </g></svg>
                                    <span className="inside-text mt-4">+91 92810 41475</span> </span>
                            </div>
                            <div className="contact-icon">
                                <span class="contact-ct-1"><svg width="64px" height="64px" viewBox="-11.52 -11.52 47.04 47.04" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_iconCarrier"> <g> <path fill="none" d="M0 0h24v24H0z"></path> <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-2.29-2.333A17.9 17.9 0 0 1 8.027 13H4.062a8.008 8.008 0 0 0 5.648 6.667zM10.03 13c.151 2.439.848 4.73 1.97 6.752A15.905 15.905 0 0 0 13.97 13h-3.94zm9.908 0h-3.965a17.9 17.9 0 0 1-1.683 6.667A8.008 8.008 0 0 0 19.938 13zM4.062 11h3.965A17.9 17.9 0 0 1 9.71 4.333 8.008 8.008 0 0 0 4.062 11zm5.969 0h3.938A15.905 15.905 0 0 0 12 4.248 15.905 15.905 0 0 0 10.03 11zm4.259-6.667A17.9 17.9 0 0 1 15.973 11h3.965a8.008 8.008 0 0 0-5.648-6.667z"></path> </g> </g></svg>
                                    <span className="inside-texts mt-3">greenheartfulnessrun@heartfulness.org</span></span>
                            </div>

                            <div class="p-3">
                                <a href="https://cdn-prod.heartfulness.org/e-magazines/English/Kanha%20Layout%20Complete%20%283%29%20%281%29.pdf" className="btn btn-primary contact-btn" download="" target="_blank" rel="noopener noreferrer" style={{ marginLeft: "40px", margiBottom: "30px" }}>Download Kanha Map</a>
                            </div>

                        </div>
                        <div className="col-sm-6"><iframe title="playing Ground" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3811.8509020442107!2d78.2141121140947!3d17.177462513348615!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcbc74c7cb44451%3A0x6fa5b0916b2ae362!2sPlaying%20Ground!5e0!3m2!1sen!2sin!4v1671768958311!5m2!1sen!2sin" width="600" height="350" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe></div>
                    </div>
                </div>
            </section>
         
        </>
    );
};
export default Contactus;
